<template>
  <v-list class="lists-menu">
    <v-subheader><h3>Filtra per:</h3></v-subheader>
    <v-list-item
      v-for="item in content"
      :key="item.id"
      :to="{ name: 'FaqList', params: { categoryName: item.slug } }"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import CMSService from "~/service/cmService";
export default {
  name: "FaqCategory",
  data() {
    return {
      content: null,
      active: null
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      let res = await CMSService.getCustomPostByFilters("categoria_faq");
      this.content = res.data;
    },
    filterByCategory(slug) {
      this.active = slug;
      this.$emit("filter", slug);
    }
  }
};
</script>
