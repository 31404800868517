<template>
  <div class="faq">
    <Breadcrumb :items="breadcrumb" />
    <v-container fluid>
      <v-row>
        <v-col cols="0" md="3" class="faq-filter-container">
          <FaqCategory />
        </v-col>
        <v-col cols="12" md="9">
          <page-title :page="page" class="mb-3" />
          <div v-if="content != null">
            <v-expansion-panels class="faq-list" accordion flat>
              <v-expansion-panel
                v-for="item in content"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header class="">
                  <h3 v-html="item.title.rendered"></h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="faq-content" v-html="item.content.rendered"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <!-- <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="faq-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $tuneVariant
                </v-icon>
              </v-btn>
            </template>
            <v-card><FaqCategory /> </v-card>
          </v-speed-dial> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.faq-title {
  font-size: 13px;
  color: $primary;
}
.faq-list {
  .v-expansion-panel-header {
    border-radius: 0;
  }
  .v-expansion-panel-header__icon {
    i {
      color: var(--v-primary-base) !important;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 16px 24px;
    p {
      margin-bottom: 0;
    }
  }
  .faq-content {
    word-wrap: break-word;
  }
}
.faq-filter {
  .v-speed-dial__list {
    min-width: 400px;
    left: initial;
    right: 0;
  }
}
</style>
<script>
import FaqCategory from "@/components/wordpress/FaqCategory";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import PageTitle from "@/components/wordpress/PageTitle.vue";
export default {
  name: "FaqList",
  components: { FaqCategory, Breadcrumb, PageTitle },
  data() {
    return {
      content: null,
      fab: false
    };
  },
  mixins: [page],
  mounted() {
    this.fetchFaqList(this.$route.params.categoryName);
    this.fetchPage("faq");
  },
  beforeRouteLeave(to, from, next) {
    // Disable scroll behavior before leaving the component
    this.$router.options.scrollBehavior = null;
    next();
  },
  methods: {
    async fetchFaqList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "faq",
        [
          {
            filterName: "filter[categoria_faq]",
            filterValue: categoryName
          }
        ],
        100
      );
      this.content = res.data;
      const faqList = document.querySelector(".faq-list");
      const faqFilterContainer = document.querySelector(
        ".faq-filter-container"
      );

      const offset = faqFilterContainer.offsetHeight;

      const scrollPosition =
        faqList.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchFaqList(categoryName);
    }
  }
};
</script>
